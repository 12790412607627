import { FireBaseAuth } from "./fireBaseAuth";
import { FirebaseConfig } from "./fireBaseConfig";
import { SessionService } from "./sessionService";
import { StorageService } from "./storage-service";
import { TopicListenerServiceImpl } from "./topicListenerService";

export class Context {
  TOPIC_LISTENER_SERVICE = new TopicListenerServiceImpl();
  SESSION_STORAGE = new StorageService();
  SESSION_SERVICE = new SessionService();
  CONFIG = new FirebaseConfig(window.location.hostname);
  FIREBASE_AUTH = new FireBaseAuth(this.CONFIG, this.SESSION_STORAGE);
}
