import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import HeaderApp from "./header-app/header-app.lazy";
import Home from "./home/home.lazy";
import { FireBaseAuth } from "./services/fireBaseAuth";
export class AppProps {
  fireBaseAuth?: FireBaseAuth;
}

export class App extends React.Component {
  fireBaseAuth: FireBaseAuth | undefined;
  constructor(props: AppProps) {
    super(props);
    this.fireBaseAuth = props.fireBaseAuth ? props.fireBaseAuth : undefined;
  }

  render() {
    return (
    
        <BrowserRouter>
          <div className="App p-0 m-0">
            <HeaderApp />
            <Home />
          </div>
        </BrowserRouter>
      
    );
  }
}
