import { CONTEXT } from "..";

export class SessionService {
  static readonly LOADING = "loading";
  static readonly LOGGED_IN = "loggedIn";
  static readonly KEYS: string[] = [this.LOADING, this.LOGGED_IN];

  session: { [key: string]: any } = {};
  static VALUE_LOADING_PAUSED = "paused";
  static FIREBASE_TOKEN: string = "firebaseToken";

  setValue(key: string, value: any) {
    this.session[key] = value;
    CONTEXT.TOPIC_LISTENER_SERVICE.publish(key, value);
  }

  getValue(key: string) {
    return this.session[key];
  }

  clearSession(key: string) {
    delete this.session[key];
  }

  clearAllSessions() {
    this.session = {};
  }
}
