import {
  EmailAuthProvider,
  GoogleAuthProvider,
  PhoneAuthProvider,
  UserCredential,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getRedirectResult,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from "firebase/auth";

import { CONTEXT } from "..";
import { networkMarker } from "./annotations/networkMarker";
import { FirebaseConfig } from "./fireBaseConfig";
import { SessionService } from "./sessionService";
import { StorageService } from "./storage-service";
export class FireBaseAuth {
  initLogin: Promise<unknown> | undefined;
  //get auth using switch case from text input
  getAuthProvider(authType: string) {
    switch (authType || this.config.providerId) {
      case GoogleAuthProvider.PROVIDER_ID:
        return new GoogleAuthProvider();
      case EmailAuthProvider.PROVIDER_ID:
      case "signUp":
        return new EmailAuthProvider();

      case PhoneAuthProvider.PROVIDER_ID:
        return new PhoneAuthProvider(this.config.auth!);
      default:
        console.log("authType not found");
    }
    return;
  }

  async signOut() {
    if (this.config.auth) {
      return signOut(this.config.auth!).then((data) => {
        // Sign-out successful.
        this.config.loggedIn = false;
        this.config.creds = null;
        this.config.user = null;
        this.config.uid = "";
        return this.config;
      });
    }
  }
  constructor(public config: FirebaseConfig, private storageService: StorageService) {
    this.config.user = this.config.auth?.currentUser;

    this.initLogin = new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        CONTEXT.SESSION_SERVICE.setValue(SessionService.LOGGED_IN, false);
        const refreshToken = await CONTEXT.SESSION_STORAGE.get(StorageService.SESSION_INFO, SessionService.FIREBASE_TOKEN);

        if (refreshToken) {
          this.config.loaded.then(() => {
            this.config
              .auth!.authStateReady()
              .then((decodedToken) => {
                this.config.user = this.config.auth!.currentUser;
                this.config.loggedIn = this.config.auth!.currentUser ? true : false;
                CONTEXT.SESSION_SERVICE.setValue(SessionService.LOGGED_IN, this.config.loggedIn);
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("Error signing in:", errorCode, errorMessage); // Handle error
              });
          });
        }
        this.handleResponse(resolve, reject, getRedirectResult(this.config.auth!));
      }, 10);
    });
  }

  async signUp(email: string, password: string, pop: boolean = true) {
    console.log("creds:" + email + " " + password);
    return createUserWithEmailAndPassword(this.config.auth!, email, password)
      .then((userCredential) => {
        console.log(" signing in---------------");
        this.config.user = userCredential.user;
      })
      .catch((error) => {
        console.log("Error signing in:");
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode + " " + errorMessage);
        if (errorCode === "auth/email-already-in-use") {
          this.signIn(email, password);
        }
      });
  }

  async signIn(email: string, password: string, pop: boolean = true) {
    return signInWithEmailAndPassword(this.config.auth!, email, password)
      .then((userCredential) => {
        console.log(" signing in---------------");
        this.config.user = userCredential.user;

        this.successLogin(userCredential);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode + " " + errorMessage);
        this.loginFailed(error);
      });
  }
  @networkMarker
  //google auth with firebase implementation
  async googleAuth(pop: boolean = true) {
    return new Promise(async (resolve, reject) => {
      await setPersistence(this.config.auth!, browserLocalPersistence);
      const provider = new GoogleAuthProvider();
      this.config.providerId = provider.providerId;
      this.config.provider = provider;
      this.handleResponse(resolve, reject, this.config.isLocalhost || pop ? signInWithPopup(this.config.auth!, provider) : signInWithRedirect(this.config.auth!, provider));
    });
  }

  authChangeAction = async (user: any) => {
    if (user) {
      try {
        CONTEXT.SESSION_STORAGE.set(StorageService.SESSION_INFO, SessionService.FIREBASE_TOKEN, await user.getIdToken());
      } catch (e) {
        console.log(e);
      }
    }
  };

  handleResponse(resolve: (value: unknown) => void, reject: (reason?: any) => void, promise: Promise<UserCredential | null>) {
    this.config.auth?.onAuthStateChanged((user) => this.authChangeAction(user));
    promise
      .then((result) => {
        resolve(result);
        this.successLogin(result);
      })
      .catch((error) => {
        this.loginFailed(error);
        reject(error);
      });
  }

  private loginFailed(error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    // const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(errorCode + " " + errorMessage + " email:" + email);
  }

  private successLogin(result: UserCredential | null) {
    if (result) {
      this.config.user = result!.user;
      this.config.loggedIn = true;
    }
  }
}
