import { Component, lazy, Suspense } from "react";
import { CONTEXT } from "..";
import { SessionService } from "../services/sessionService";
import type { HeaderAppProps, HeaderAppState } from "./header-app";

const LazyHeaderApp = lazy(() => import("./header-app"));

export class HeaderApp extends Component<HeaderAppProps, HeaderAppState> {
  private _isMounted: boolean = false;
  constructor(props: HeaderAppProps) {
    super(props);
    CONTEXT.TOPIC_LISTENER_SERVICE.subscribe(SessionService.LOGGED_IN, (data) => {
      console.log("Logged in");
      if (this._isMounted) {
        this.forceUpdate();
      }
    });
  }
  componentDidMount(): void {
    this._isMounted = true;
  }

  render() {
    return (
      <Suspense fallback={null}>
        <LazyHeaderApp {...this.props} />
      </Suspense>
    );
  }
}

export default HeaderApp;
