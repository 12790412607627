import React from "react";
import ReactDOM from "react-dom/client";
import "reflect-metadata";
import { App } from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Context } from "./services/Context";

export const CONTEXT = new Context();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <script type="module">import reactBootstrap from 'https://cdn.jsdelivr.net/npm/react-bootstrap@2.10.2/+esm';</script>
    <App />
  </React.StrictMode>
);

reportWebVitals();
