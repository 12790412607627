// src/services/TopicListenerService.ts

import { Subject } from "rxjs";

export interface TopicListenerService {
  topics: Record<string, Subject<any>>;

  subscribe(topic: string, callback: (data: any) => void): void;

  publish(topic: string, data: any): void;
}

export class TopicListenerServiceImpl implements TopicListenerService {
  topics: Record<string, Subject<any>> = {};

  subscribe(topic: string, callback: (data: any) => void): void {
    if (!this.topics[topic]) {
      this.topics[topic] = new Subject<any>();
    }
    this.topics[topic].subscribe(callback);
  }

  publish(topic: string, data: any): void {
    if (!this.topics[topic]) {
      this.topics[topic] = new Subject<any>();
    }
    this.topics[topic].next(data);
  }
}
 
