import React, { lazy, Suspense } from "react";

const LazyHome = lazy(() => {
  const imports = import("./home").then(({ Home }) => ({ default: Home }));
 
  return imports;
});

const Home = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense >
    <LazyHome {...props} />
  </Suspense>
);

export default Home;
