import { CONTEXT } from "../..";
import { SessionService } from "../sessionService";

export function  networkMarker(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    // keep a reference to the original function
    const originalValue = descriptor.value;

    // Replace the original function with a wrapper
    descriptor.value = function (...args: any[]) {
    CONTEXT.SESSION_SERVICE.setValue(SessionService.LOADING, true);
      // Call the original function
      var result = originalValue.apply(this, args);

      if (result instanceof Promise) {
        result.then(() => {
          CONTEXT.SESSION_SERVICE.setValue(SessionService.LOADING, SessionService.VALUE_LOADING_PAUSED);
        });
      } else {
        CONTEXT.SESSION_SERVICE.setValue(SessionService.LOADING, SessionService.VALUE_LOADING_PAUSED);
      }
      return result;
    };
  }