export class StorageService {
  private db: IDBDatabase | undefined;
  static SESSION_INFO = "sessionInfo";
  initJob: Promise<void> | undefined;
  // decalre a list to store static strings
  static STORES = [StorageService.SESSION_INFO];
  constructor() {
    this.initJob = this.openDatabase();
  }

  private async openDatabase(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(window.location.hostname, 6);
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        for (const store of StorageService.STORES) {
          if (!this.db.objectStoreNames.contains(store)) {
            this.db.createObjectStore(store);
          }
        }
      };
      request.onsuccess = (event: Event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        resolve();
      };
      request.onerror = (event: Event) => {
        console.error(`Database error: ${(event.target as IDBOpenDBRequest).error}`);
        reject(`Couldn't open database ${window.location.hostname}`);
      };
    });
  }

  async set(storeName: string, key: string, value: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.db?.transaction([storeName], "readwrite");
      const objectStore = transaction?.objectStore(storeName);
      const request = objectStore?.put(JSON.stringify(value), key);
      transaction?.commit();
      transaction?.addEventListener("complete", () => {
        resolve();
      });
      transaction?.addEventListener("error", () => {
        console.log(`Couldn't write data to ${storeName}`);
        reject();
      });
    });
  }

  async get(storeName: string, key: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.initJob;
      const transaction = this.db?.transaction([storeName], "readonly");
      const objectStore = transaction?.objectStore(storeName);
      const request = objectStore?.get(key);

      request?.addEventListener("success", () => {
        if (!request?.result) {
          resolve(null);
          return;
        }
        resolve(JSON.parse(request?.result));
      });

      request?.addEventListener("error", () => {
        reject(`Couldn't read data from ${storeName}`);
      });
    });
  }

  async delete(storeName: string, key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.db?.transaction(storeName, "readwrite");
      const objectStore = transaction?.objectStore(storeName);
      const request = objectStore?.delete(key);
      transaction?.commit();
      transaction?.addEventListener("complete", () => {
        resolve();
      });

      transaction?.addEventListener("error", () => {
        reject(`Couldn't delete data from ${storeName}`);
      });
    });
  }
}
