import { FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, GoogleAuthProvider, OAuthCredential, OAuthProvider, User, connectAuthEmulator, getAuth } from "firebase/auth";
import { LogoutPayload } from "../session/LoginPane/types";

export class FirebaseConfig implements LogoutPayload {
  providerId: string | undefined;
  uid: string = "";
  email: string = "";
  auth: Auth | undefined;
  AuthCredential: any;
  firebaseConfig = {};
  app: any;
  provider: GoogleAuthProvider | OAuthProvider | undefined;
  loggedIn: boolean = false;
  creds: OAuthCredential | null = null;
  user: User | undefined | null = null;
  isLocalhost: boolean = false;
  loaded: Promise<void> = Promise.resolve();

  constructor(private website: string) {
    this.firebaseConfig = {
      authDomain: window.location.hostname + (window.location.port ? ":" + window.location.port : ""),
    };
    this.loaded = this.loadconfigFromServer().then(() => {
      this.app = initializeApp(this.firebaseConfig);
      this.auth = getAuth(this.app);
      console.log("FirebaseConfig current user email: " + this.auth.currentUser?.email);

      if (window.location.hostname === "localhost") {
        this.isLocalhost = true;
        connectAuthEmulator(this.auth, "http://127.0.0.1:9099");
      }
    });
  }
  async loadconfigFromServer(): Promise<void> {
    
    return fetch("https://storage.googleapis.com/storage.a2a.dev/secure/fbcon.json")
      .then((response) => response.json())
      .then((data) => {
        this.firebaseConfig = { ...data, ...this.firebaseConfig };
      });
  }
}
